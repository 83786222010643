import Head from 'next/head';
import Script from 'next/script';

import '../styles/globals.css';

const Description =
		'Salobo Investments is a holding company dedicated to managing diversified investments for our partnerships. We specialize in investing in various industries in Singapore and the Philippines, with a focus on long-term growth and value creation. Our team is committed to delivering exceptional results through careful assessment and management of our investment portfolio. With a transparent and collaborative approach, we aim to help our partners achieve their financial objectives. Partner with Salobo Investments for strategic investment solutions that drive long-term success.',
	Image = '/logo.png',
	Title = 'Salobo Investments: Your Partner for Diversified Investments in Singapore and the Philippines',
	Url = 'https://www.saloboinvestments.com/';

function MyApp({ Component, pageProps }) {
	return (
		<>
			<Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=G-Y2E4N1TD3V`} />
			<Script strategy="lazyOnload">
				{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'Y2E4N1TD3V', { page_path: window.location.pathname });
                `}
			</Script>

			<Head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />

				<title>{Title}</title>
				<meta name="title" content={Title} />
				<meta name="description" content={Description} />
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta httpEquiv="content-language" content="en-us" />
				<meta name="language" content="English" />
				<meta property="og:title" content={Title} />
				<meta property="og:description" content={Description} />
				<meta property="og:image" content={Image} />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content={Title} />
				<meta property="og:url" content={Url} />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content={Title} />
				<meta name="twitter:description" content={Description} />
				<meta name="twitter:image" content={Image} />
				<link rel="icon" href="/favicon.ico" />
				<meta name="publisher" content="Volenday" />
			</Head>
			<Component {...pageProps} />
		</>
	);
}

export default MyApp;
